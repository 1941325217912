.marker-type-selector {
  margin-bottom: 30px;
}

.list {
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
}

.title {
  color: var(--color-text-main);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  padding-left: 12px;
}

.item {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  color: var(--color-text-main);
  font-size: 12px;
  font-style: normal;
  margin-right: 12px;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 8px;
}

.item-active {
  border: 1px solid var(--fill-6-dark, #272928);
  font-weight: 700;
}
