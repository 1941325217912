.map-page {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-container {
  position: relative !important;
  top: var(--height-nav) !important;
  width: 100% !important;
  height: calc(100% - var(--height-nav)) !important;
  overflow: hidden;
  background: #f1f1f1;
  display: block !important;
  z-index: 1 !important;
}

.map-container.iframe {
  top: 0!important;
  height: 100% !important;
}

.map-container-fixed {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background: #f1f1f1;
  display: block !important;
  z-index: 1 !important;
  top: 0!important;
}

.top-menu {
  width: 100vw;
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  overflow: auto;
  flex-flow: row nowrap;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  min-height: 30px;
  z-index: 2;
  justify-content: center;
}

@media (max-width: 796px) {
  .top-menu {
    justify-content: flex-start;
  }
}

@media (max-width: 450px) {
  .top-menu::-webkit-scrollbar {
    display: none;
  }
}

.menu-item {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  color: var(--color-ui-2, #1B2028);
  font-size: 12px;
  margin: 0 8px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

.menu-item-create {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  color: var(--color-ui-2, #1B2028);
  background: var(--color-theme);
  font-size: 12px;
  margin: 0 8px;
  font-weight: 800;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

.menu-item-active {
  background: #292526;
  color: #fff;
}

.marker-list {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  max-width: 1050px;
  width: 100vw;
  height: 180px;
  left: 50%;
  box-sizing: border-box;
  transform: translateX(-50%);
}

.nodata {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 16px;
  padding: 8px 14px;
  border-radius: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  width: 42px;
  height: 42px;
  transform: translateY(-50%);
}

.slide-left {
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  width: 42px;
  height: 42px;
  transform: translateY(-50%) rotate(180deg);
}

.slide-left-wide {
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  width: 42px;
  height: 42px;
  transform: translateY(-50%) rotate(180deg);
  margin-left: -21px;
}

.slide-wide {
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  width: 42px;
  height: 42px;
  transform: translateY(-50%);
  margin-right: -21px;
}

.follow-window {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 220px;
  width: 100%;
  max-height: 108px;
  z-index: 2;
  justify-content: center;
  align-items: center;
}

.dialog-creat-marker {
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #c0c0c0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px 0;

  .dialog-inner {
    overflow: auto;
    height: 100%;
  }
}

.selecting-point {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  background: #FFF;
  box-shadow: 0 1.988px 18px 0 rgba(0, 0, 0, 0.10);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;

  div {
    color: var(--Color-UI-2, #1B2028);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .text {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .btn {
    cursor: pointer;
    border-radius: var(--Radii-radius-button, 6px);
    background: var(--Error-5-Normal, #F64F4F);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Text-0-White, #FFF);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    padding: 8px 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
