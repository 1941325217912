.dialog-feedback {
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #c0c0c0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;

  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;

    svg {
      cursor: pointer;
    }
  }

  .btn {
    display: flex;
    height: 50px;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Fill-2-Medium, #F7F7F7);
    box-sizing: border-box;
    margin-top: 18px;

    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
    }
  }

  .des {
    color: var(--Text-4-Headline, #272928);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 25px;
  }
}

.help-btn {
  position: fixed;
  z-index: 999;
  width: 56px;
  height: 56px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  right: 40px;
  bottom: 96px;
  background: #fff;
  cursor: pointer;

  @media (max-width: 450px) {
    & {
      right: 14px;
    }
  }
}
