.dialog-guide-follow {
  max-width: 350px;
  width: 100%;
  height: 108px;
  border-radius: 12px;
  background: var(--color-card-bg);
  padding: 16px;
  box-sizing: border-box;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.user {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--color-text-main);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  max-width: 60%;
}

.avatar {
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 50%;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.des {
  color: var(--color-text-main);
  font-size: 12px;
  margin-top: 14px;
}
