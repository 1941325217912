.dialog {
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #c0c0c0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;
}

.link {
  border-radius: var(--Radii-radius-button, 6px);
  background: var(--Fill-2-Medium, #F7F7F7);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  display: flex;
  height:  40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  text-decoration: none;

  color: var(--Text-4-Headline, #272928);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
}

.title {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.des {
  color: var(--Text-4-Headline, #272928);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 28px;
}

.copy {
  display: flex;
  height:  40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  text-decoration: none;

  color: var(--Text-4-Headline, #272928);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
