.ticket-purchased {
  order: 3;
}

.ticket-info {
  border-radius: 20px;
  background: var(--Fill-0-White, #FFF);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .tickets {
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  @media(max-width: 650px) {
    & {
      margin: 12px 20px;
      order: 2;
    }
  }

  .date {
    color: #787878;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 4px;
    line-height: 24px; /* 150% */
    padding-bottom: 8px;
  }

  .title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--Text-4-Headline, #272928);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    justify-content: space-between;

    .left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        min-width: 24px;
        border-radius: 50%;
      }
    }

    .right {
      flex-flow: nowrap;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--Text-4-Headline, #272928);
      font-size: 12px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 18px;

      img {
        border-radius: 50%;
      }
    }

    img {
      margin-right: 8px;
    }
  }

  .name {
    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 11px;
  }
}

.scroll {
  overflow-y: auto;
}
