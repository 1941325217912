.marker-card {
  background: var(--color-card-bg);
  height: 180px;
  border-radius: 20px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 20px 16px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  text-decoration: none;
  color: var(--color-text-main);
}

.tags {
  margin-top: 4px;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 12px;
  white-space: nowrap;
}

.left {
  max-width: calc(100% - 96px);
  width: calc(100% - 96px);
  display: flex;
  flex-flow: column nowrap;
}

.right {
  width: 96px;
  min-width: 96px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.title {
  color: var(--color-text-main);
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cover {
  width: 64px;
  height: 64px;
  overflow: hidden;
}

.img {
  object-fit: cover;
  top: 0;
  width: 100%;
  height: 100%;
}

.des {
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 5px;
}

.detail {
  color: var(--color-text-main);
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  margin-top: 2px;

  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 2px;
  }
}

.link-icon {
  margin-left: 4px;
}

.info {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.icon {
  margin-right: 6px;
}

.checkin-btn {
  border-radius: 16.5px;
  background: var(--primary-color-brand-16, #9EFEDD);
  display: inline-flex;
  height: 33px;
  padding: 0 16.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-4-headline, #272928);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.creator {
  display: flex;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 7px;
  align-items: center;

  img {
    margin-right: 4px;
  }
}

.avatar {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 4px;
}

.checked {
  display: flex;
  flex-flow: row nowrap;
}

.marker-card-bg {
  background: var(--color-card-bg);
  height: 180px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px 14px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  background: linear-gradient(276deg, #F7DF3A -18.27%, #D2F8E8 59.84%);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.08);
}
